<template>
  <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4"> 
    <div class="container" id="custom-cards">
      <div class="row row-cols-1 row-cols-lg-8 align-items-stretch g-4 py-5">
        <div class="col">
          <h3 class="pb-2 border-bottom text-dark-blue">Add New Project </h3> 
          <div class="card card-cover overflow-hidden text-white bg-white rounded-5 shadow-lg">
            <div class="d-flex flex-column  p-4 pb-3 text-blue text-shadow-1">
              <div style="display:inline !important">
                <h6 class="text-start card-title fw-bold">
                  Project Info
                </h6>
                <div class="d-flex justify-content-center align-items-center">
                  <div style="display:inline !important">
                    <div class="d-flex justify-content-center align-items-center">                            
                      <div class="container">  
                        <form class="row g-3 justify-content-center align-items-center">
                          <div class="col-md-4" align="left">
                            <label for="validationDefault01" class="form-label">Name</label>
                            <input type="text" class="form-control" id="validationDefault01" required placeholder="Name of Project">
                          </div>
                          <div class="col-md-4" align="left">
                            <label for="validationDefault02" class="form-label">Organization</label>
                            <select class="form-select" id="validationDefault02" required>
                              <option selected disabled value="">Choose Organization</option>
                              <option>Organization 1</option>
                              <option>Organization 2</option>
                            </select>
                          </div>
                          <div class="col-md-4" align="left">
                            <label for="validationDefault03" class="form-label">Leader of Project</label>
                            <select class="form-select" id="validationDefault03" required>
                              <option selected disabled value="">Choose Leader</option>
                              <option>Member 1</option>
                              <option>Member 2</option>
                            </select>
                          </div>                                               
                          <div class="col-md-3" align="left">
                            <label for="validationDefault04" class="form-label">Status</label>
                            <select class="form-select" id="validationDefault04" required>
                              <option selected disabled value="">Status of Project</option>
                              <option>Active</option>
                              <option>Inactive</option>
                              <option>Done</option>
                            </select>
                          </div>                                               
                          <div class="col-md-3" align="left">
                            <label for="validationDefault06" class="form-label">Team</label>
                            <select class="form-select" id="validationDefault06" required>
                              <option selected disabled value="">Select Team</option>
                              <option>Team 1</option>
                              <option>Team 2</option>
                              <option>Team 3</option>
                            </select>
                          </div>
                          <div class="col-md-3" align="left">
                            <label for="validationDefault08" class="form-label">Date start</label>
                            <input class="form-control" type="date" id="datestart" name="date">
                          </div>
                          <div class="col-md-3" align="left">
                            <label for="validationDefault09" class="form-label">Date end</label>
                            <input class="form-control" type="date" id="dateend" name="date">
                          </div>  
                          <div class="d-flex justify-content-end mt-3 m-1">
                            <button class="button-1 btn btn-primary" type="submit">Save Project</button>
                          </div>
                        </form>
                      </div>
                    </div>                    
                  </div>
                </div>                    
              </div>
            </div>                
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
  export default
  {
    name: 'ContentAddProject'
  }
</script>

<style></style>
